.svg-rotate {
  -webkit-animation: rotating 1s linear infinite;
  -moz-animation: rotating 1s linear infinite;
  -ms-animation: rotating 1s linear infinite;
  -o-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.no-data-image {
  width: 100%;
  max-width: 350px;
  height: 100%;
  object-fit: contain;
}

.p-component {
  font-family: "Jost", sans-serif;
}

.p-datatable {
  margin-top: 20px;

  .p-datatable-table {
    .p-datatable-thead {
      tr > th {
        font-weight: 600;
        font-size: 12px;
        text-transform: uppercase;
        color: var(--Dark-dark_3);
        background-color: var(--Primary-main_shade_light_2);
        border-top-width: 1px;

        &:hover {
          background-color: var(--Primary-main_shade_light_2) !important;
        }
      }
    }
  }

  .p-sortable-column {
    &:focus {
      box-shadow: none;
      outline: none;
    }
    .p-sortable-column-icon {
      width: 12px;
      height: 12px;
    }
  }

  .actionDropdown {
    .btn-primary,
    .btn-primary:active {
      background-color: transparent;
      color: #50506a;
      border: transparent;
      &::after {
        content: initial;
      }
    }
  }
}

.custom-select {
  width: 100%;
  .custom-select__control {
    color: var(--Dark-dark_2);
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    background: var(--Dark-dark_7);
    border: 1px solid var(--Dark-dark_5);
    border-radius: 50px;
    padding: 0 0 0 16px;
    height: 50px;

    .custom-select__indicator-separator {
      display: none;
    }
  }

  &.custom-select--is-disabled {
    cursor: not-allowed;
    .custom-select__single-value--is-disabled {
      color: var(--Dark-dark_2);
    }
  }

  .custom-select__menu-list {
    max-height: 365px;
  }

  .custom-select__group-heading {
    background: var(--Dark-dark_6);
    margin: 0.5em 0 0.3em;
    padding: 10px 12px;

    .select-group-header {
      align-items: center;
      display: flex;
      font-size: 14px;
      justify-content: space-between;

      .select-group-title {
        color: var(--primary-main);
        text-transform: capitalize;
      }

      .select-group-badge {
        background-color: #fff;
        border-radius: 2em;
        color: #000;
        display: inline-block;
        font-size: 12px;
        font-weight: normal;
        line-height: 1;
        min-width: 28px;
        padding: 0.5em;
        text-align: center;
      }
    }
  }
}

.custom-select.is-invalid {
  .custom-select__control {
    border: 1px solid #dc3545;
  }
}

.custom-multi-select {
  .custom-select__control {
    border-radius: 6px;
    min-height: 50px;
    height: auto;
    padding: 0;
  }

  .custom-select__multi-value {
    background: var(--primary-main);
    border-radius: 4px;
    color: #fff;

    .custom-select__multi-value__label {
      color: #fff;
      font-size: 14px;
      padding: 4px 8px;
    }
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin: -6px;
  .tag {
    padding: 5px 10px;
    font-size: 13px;
    margin: 6px;
    border-radius: 50px;
    border: 1px solid;
    span {
      margin-right: 15px;
    }
  }
}

.status-danger {
  color: var(--Status-danger);
  background-color: var(--Status-danger_shade);
}

.status-warning {
  color: var(--Status-warning);
  background-color: var(--Status-warning_shade);
}

.status-success {
  color: var(--Status-success);
  background-color: var(--Status-success_shade);
}

.accordion {
  .accordion-button {
    padding: 8px 16px;
    font-weight: 500;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.res-table {
  .table {
    tr {
      &:nth-child(odd) {
        background: var(--white);
      }
    }
    tr {
      &:nth-child(even) {
        background: var(--Dark-dark_7);
      }
    }
  }
}

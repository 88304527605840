.flex-coul {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.mainContentWrapper {
  min-height: 100vh;

  .sidebarContent {
    min-height: calc(100vh - 74px);
  }
}

.homeContent {
  display: flex;
  position: sticky;
  top: 0;
  padding: 22px 12px;
  max-width: 260px;
  min-width: 260px;
  height: calc(100vh - 74px);
  overflow: auto;
  transition: all 0.5s ease;

  &::-webkit-scrollbar {
    display: none;
  }

  ul {
    padding: 0;
    margin: 0;
    gap: 12px;

    li {
      list-style: none;
      border: none;
      border-radius: 8px;
      width: 100%;
      text-align: left;
      cursor: pointer;
      background: transparent;
      padding: 13px 15px 13px 15px;
      transition: 0.3s ease;

      h6 {
        margin-left: 15px;
      }
    }

    li {
      &:hover,
      &.active {
        background: linear-gradient(90deg, rgba(0, 0, 255, 0.2) 0%, rgba(0, 0, 255, 0) 100%);
        color: var(--primary-main);

        p {
          color: var(--primary-main) !important;
        }
      }

      svg {
        font-size: 18px;
      }
    }

    .sub-menulist {
      margin-top: 14px;
      padding-left: 14px;

      h6 {
        white-space: nowrap;
      }
    }

    .main-menu-wrapper.sub-menu-open {
      background: linear-gradient(90deg, rgba(0, 0, 255, 0.1) 10%, rgba(0, 0, 255, 0) 100%);
      border-radius: 8px;
    }
  }
}

.header-menu {
  @media (max-width: 991px) {
    background-color: var(--white);
  }

  .toggleBtn {
    display: none;

    @media (max-width: 991px) {
      display: block;
    }
  }

  .main-header {
    padding: 15px 48px;
    gap: 20px;

    @media (max-width: 1200px) {
      padding: 15px 24px;
    }

    @media (max-width: 991px) {
      padding: 15px 16px;
    }

    @media (max-width: 767px) {
      justify-content: flex-end !important;
    }

    .search-box {
      width: 100%;
      max-width: 480px;

      @media (max-width: 767px) {
        display: none !important;
      }

      button {
        height: 42px;
        border-radius: 50px 0 0 50px;
        background-color: var(--white);
        padding: 0 10px 0 20px;
        border: 1px solid var(--Dark-dark_5);
        border-right: 0;
      }

      input {
        height: 42px;
        border-radius: 0 50px 50px 0;
        border: 1px solid var(--Dark-dark_5);
        border-left: 0;
        outline: none;
      }
    }
  }

  .bg-white-circle {
    width: 36px;
    height: 36px;
    background-color: var(--white);
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0px 15px 40px rgba(22, 8, 49, 0.1);

    @media (max-width: 767px) {
      width: 26px;
      height: 26px;
    }

    svg {
      height: 20px;
      width: 20px;

      @media (max-width: 767px) {
        width: 16px;
        height: 16px;
      }
    }
  }

  .user-info {
    .userIcon {
      max-width: 42px;
      min-height: 42px;

      @media (max-width: 767px) {
        max-width: 26px;
        min-height: 26px;
      }
    }

    .avatar {
      background-color: var(--primary-main);
      height: 42px;
      width: 42px;
      border-radius: 50px;
      text-align: center;
      line-height: 42px;
      font-size: 20px;
      border: 2px solid var(--white);
      font-weight: 500;
      color: var(--white);
    }

    .dropdown-toggle {
      &::after {
        content: none;
      }
    }

    svg {
      margin-left: 10px;
      width: 12px;
    }

    p {
      &:first-child {
        color: var(--Dark-dark_1);
        font-weight: 700;
      }
    }
  }

  .user-info {
    p {
      &:nth-child(2) {
        font-size: 13px;
      }
    }
  }

  .menu-col-1 {
    min-width: 260px;
    padding: 22px 12px;

    @media (max-width: 991px) {
      background-color: transparent !important;
    }
  }

  .google-logout {
    box-shadow: none !important;
    width: 100%;

    &:hover {
      background-color: #e9ecef !important;
    }

    span {
      color: #212529 !important;
      padding: 4px 16px !important;
      font-size: 16px !important;
    }

    & > div {
      display: none;
    }
  }
}

.mainContentWrapper {
  min-height: 100vh;

  .overlaySidebar {
    width: calc(100% - 260px);
    height: 100%;
    top: 0;
    left: 260px;
    position: absolute;
    background: #0000ff33;
    z-index: 98;
  }

  @media (max-width: 991px) {
    .open {
      .sidebarContent {
        .homeContent {
          position: absolute;
          top: 74px;
          height: calc(100% - 74px);
          left: 0;
          z-index: 99;
        }
      }
    }

    .close {
      .sidebarContent {
        .homeContent {
          position: absolute;
          top: 74px;
          height: calc(100% - 74px);
          left: -100%;
        }
      }
    }
  }

  .sidebarContent {
    min-height: calc(100vh - 74px);

    .contentWrapper {
      padding: 48px;
      height: calc(100vh - 74px);

      @media (max-width: 1200px) {
        padding: 24px;
      }

      @media (max-width: 991px) {
        padding: 16px;
      }
    }
  }
}

.custom-table {
  &.table {
    border-collapse: collapse;
    border-radius: 6px;
    vertical-align: middle;

    thead {
      border-radius: 6px 6px 0 0;

      th {
        font-weight: 600;
        font-size: 12px;
        text-transform: uppercase;
        color: var(--Dark-dark_3);
        background-color: var(--Primary-main_shade_light_2);
        border-top-width: 1px;
      }
    }

    th,
    td {
      padding: 13px 20px;

      @media (max-width: 575px) {
        padding: 10px 6px;
      }

      .actionDropdown {
        .btn-primary {
          background-color: transparent;
          color: #50506a;
          border: transparent;

          &::after {
            content: initial;
          }
        }
      }
    }
  }
}

.tree-graph-wrapper {
  border: 1px solid var(--Dark-dark_5);
  border-radius: 5px;
  flex-grow: 1;
  width: 100%;
  height: calc(100vh - 365px);

  path.rd3t-link {
    fill: none;
    stroke: #5353ff;
    stroke-width: 1.5px;
  }

  .node__root,
  .node__branch,
  .node__leaf {
    circle {
      fill: #5353ff;
      stroke: #5353ff;
      stroke-width: 1.5px;
      r: 15;
    }

    text {
      font-size: 20px;
      font-weight: normal;
      background-color: black;
      fill: black;
    }
  }

  .rd3t-node.node__root,
  .rd3t-node.node__branch {
    .rd3t-label {
      transform: translate(0px, -30px);

      .rd3t-label__title {
        text-anchor: middle;
      }
    }
  }

  .rd3t-leaf-node.node__branch {
    .rd3t-label {
      transform: translate(-6px, 6px);
    }
  }
}

.form-switch {
  display: flex !important;
  align-items: center !important;
  padding-left: 48px !important;

  .form-check-input {
    width: 3em !important;
    height: 24px !important;
    margin-left: -48px !important;
    outline: none;
    box-shadow: none;

    &:hover,
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
    }
  }

  .form-check-label {
    margin: 0 0 0 12px !important;
  }
}

.recharts-sector,
.recharts-layer.recharts-pie {
  outline: none;
}

.date-picker {
  width: 375px !important;
}

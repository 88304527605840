.action-container {
  display: flex;
  justify-content: space-around;
  border-bottom: 0px;
}

.th-action {
  text-align: center;
}

.view-link {
  text-decoration: none;
}

.item-tr {
  border-bottom: 1px solid red;
}

.empty-str {
  text-align: center;
}

@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import "./BrandProductImages.scss";
@import "./EditorialComment.scss";
@import "./HeaderSidebar.scss";
@import "./Home.scss";
@import "./Login.scss";
@import "./postPreview.scss";
@import "./RefundRequests.scss";
@import "./SeoBlogWriter.scss";
@import "./ShowPlan.scss";
@import "./SocialMediaWriter.scss";
@import "./treeGraph.scss";
@import "./Users.scss";
@import "./eCommerce.scss";

body {
  margin: 0;
  font-family: "Jost", sans-serif;
  color: #00002f;
  background: linear-gradient(90deg, rgb(0 0 255 / 12%) 0%, hsl(39deg 100% 68% / 17%) 100%);
}

:root {
  // ** Primary Color **
  --primary-main: #0000ff;
  --Primary-main_shade_light_1: #7777ff;
  --Primary-main_shade_light_2: #eeeeff;

  // ** Secondary Color **
  --Secondary-secondary_1: #00aaff;
  --Secondary-secondary_shade_1: #edf9ff;
  --Secondary-secondary_2: #aa00ff;
  --Secondary-secondary_shade_2: #f8eaff;

  // ** Dark Color **
  --Dark-dark_1: #00002f;
  --Dark-dark_2: #202049;
  --Dark-dark_3: #50506a;
  --Dark-dark_4: #7b7b96;
  --Dark-dark_5: #c2c2d3;
  --Dark-dark_6: #ececf4;
  --Dark-dark_7: #f4f4f9;

  // ** Status Color **
  --Status-info: #1e90ff;
  --Status-info_shade: #ecf6ff;
  --Status-success: #3eb489;
  --Status-success_shade: #effff9;
  --Status-warning: #ffba00;
  --Status-warning_shade: #fff6df;
  --Status-danger: #ed2939;
  --Status-danger_shade: #fff1f2;

  // ** others **
  --white: #ffffff;
  --opacity-border: rgba(0, 0, 47, 0.1);
  --dark-blue: #1a0db3;
}

// *** scrollbar ***
// /* Firefox */
// * {
//   scrollbar-width: thin;
//   scrollbar-color: #c1c1c1 #f2f2f2;
// }

/* Chrome, Edge and Safari */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #f2f2f2;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #c1c1c1;
}

// *** font ***
h1 {
  font-size: 36px;
  line-height: 46px;
}

h2 {
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 25px;

  @media (max-width: 1440px) {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 16px;
  }

  @media (max-width: 767px) {
    font-size: 26px;
    line-height: 30px;
  }

  @media (max-width: 575px) {
    font-size: 22px;
    line-height: 28px;
  }
}

h3 {
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 0;

  @media (max-width: 1440px) {
    font-size: 22px;
    line-height: 26px;
  }

  @media (max-width: 767px) {
    font-size: 20px;
  }

  @media (max-width: 575px) {
    font-size: 18px;
    line-height: 24px;
  }
}

h4 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  margin-bottom: 0;

  @media (max-width: 991px) {
    font-size: 16px;
    line-height: 22px;
  }
}

h5 {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;

  @media (max-width: 991px) {
    font-size: 15px;
    line-height: 20px;
  }

  @media (max-width: 575px) {
    font-size: 14px;
  }
}

h6 {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 0;

  @media (max-width: 575px) {
    font-size: 12px;
    line-height: 16px;
  }
}

p {
  color: var(--Dark-dark_3);
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 400;

  @media (max-width: 991px) {
    font-size: 13px;
  }

  @media (max-width: 575px) {
    font-size: 12px;
    line-height: 16px;
  }
}

.fs-12 {
  font-size: 12px;
}

.font-weight-500 {
  font-weight: 500;
}

// *** color ***
.bg-Secondary-secondary-shade-2 {
  background-color: var(--Secondary-secondary_shade_2);
}

.bg-Primary-main-shade-light-2 {
  background-color: var(--Primary-main_shade_light_2);
}

.bg-primary-main-dark {
  background-color: var(--dark-blue);
}

.bg-primary-main {
  background-color: var(--primary-main);
}

.color-dark {
  color: var(--Dark-dark_1);
}

.color-Dark-dark_5 {
  color: var(--Dark-dark_5);
}

.color-Dark-dark_4 {
  color: var(--Dark-dark_4);
}

.color-primary-main {
  color: var(--primary-main);
}

.color-dark-blue {
  color: var(--dark-blue);
}

.color-skyblue {
  color: var(--Secondary-secondary_1);
}

.color-Secondary-secondary-2 {
  color: var(--Secondary-secondary_2);
}

.color-light-text {
  color: var(--Dark-dark_3);
}

// *** border ***
.border-radius-12 {
  border-radius: 12px;
}

.dark-border-bottom {
  border-bottom: 1px solid var(--opacity-border);
}

.light-border-1 {
  border: 1px solid var(--Dark-dark_6);
}

// *** modal ***
.modal-backdrop {
  background: var(--Dark-dark_1);
}

.modal-backdrop.show {
  opacity: 0.4;
}

// *** margin-padding ***
.p-30 {
  padding: 30px;

  @media (max-width: 1440px) {
    padding: 20px;
  }

  @media (max-width: 767px) {
    padding: 15px;
  }
}

.p-20 {
  padding: 20px;

  @media (max-width: 767px) {
    padding: 12px;
  }
}

.pb-12 {
  padding-bottom: 12px;

  @media (max-width: 575px) {
    padding-bottom: 8px;
  }
}

.mt-30 {
  margin-top: 30px;
}

.mt-20 {
  margin-top: 20px;

  @media (max-width: 767px) {
    margin-top: 15px;
  }

  @media (max-width: 575px) {
    margin-top: 10px;
  }
}

.mt-12 {
  margin-top: 12px;

  @media (max-width: 575px) {
    margin-top: 8px;
  }
}

.ml-12 {
  margin-left: 12px;
}

.mb-20 {
  margin-bottom: 20px;

  @media (max-width: 767px) {
    margin-bottom: 15px;
  }

  @media (max-width: 575px) {
    margin-bottom: 10px;
  }
}

.pointer {
  cursor: pointer;
}

pre {
  font-family: "Jost", sans-serif;
}

button,
input {
  &:focus,
  &:active,
  &:focus-visible {
    outline: none !important;
    box-shadow: none !important;
  }
}

.customPaginate {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;

  li {
    list-style: none;

    &:first-child {
      a {
        border-radius: 4px 0 0 4px;
      }
    }

    &:last-child {
      a {
        border-radius: 0 4px 4px 0;
      }
    }

    a {
      padding: 0.375rem 0.75rem;
      border: 1px solid var(--Dark-dark_5);
      margin-left: -1px;
      text-decoration: none;
      color: var(--primary-main) !important;
    }

    &.selected {
      a {
        background-color: var(--primary-main) !important;
        color: var(--white) !important;
        border: 1px solid var(--primary-main);
      }
    }

    &.disabled {
      a {
        color: var(--Dark-dark_4) !important;
      }
    }
  }
}

.custom-loader {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  background: rgb(0 0 0 / 35%);
  left: 0;
  top: 0;

  img {
    width: 400px;
  }
}

// *** button style ***
.light-btn {
  font-size: 14px;
  font-weight: 600;
  border: none;
  border-radius: 50px;
  padding: 12px 24px;

  @media (max-width: 767px) {
    padding: 12px 18px;
    font-size: 13px;
  }

  @media (max-width: 575px) {
    padding: 6px 13px;
    gap: 4px;
    font-size: 12px;
  }
}

.addlly-outline {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  padding: 15px 30px;
  border-radius: 50px;
  gap: 8px;
  border: 1px solid var(--primary-main);
  color: var(--primary-main);
  background-color: transparent;
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    font-size: 14px;
    padding: 12px 18px;
  }

  @media (max-width: 575px) {
    padding: 6px 13px;
    gap: 4px;
    font-size: 12px;
  }
}

.addlly-primary {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  padding: 15px 30px;
  border-radius: 50px;
  gap: 8px;
  border: 1px solid var(--primary-main);
  color: var(--white);
  background: var(--primary-main);
  display: flex;
  align-items: center;

  &:disabled {
    background: var(--Primary-main_shade_light_1);
    border: 1px solid var(--Primary-main_shade_light_1);
  }

  @media (max-width: 767px) {
    font-size: 14px;
    padding: 12px 18px;
  }

  @media (max-width: 575px) {
    padding: 6px 13px;
    gap: 4px;
    font-size: 12px;
  }

  &.btn-sm {
    font-size: 12px;
    line-height: 12px;
    padding: 14px 20px;
  }
}

.addlly-secondary {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  padding: 15px 30px;
  border-radius: 50px;
  gap: 8px;
  border: 1px solid var(--Primary-main_shade_light_2);
  color: var(--primary-main);
  background: var(--Primary-main_shade_light_2);
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    font-size: 14px;
    padding: 12px 18px;
  }

  @media (max-width: 575px) {
    padding: 6px 13px;
    gap: 4px;
    font-size: 12px;
  }
}

.outline-secondary2 {
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  padding: 8px 16px;
  border-radius: 50px;
  gap: 8px;
  border: 1px solid var(--Secondary-secondary_2);
  color: var(--Secondary-secondary_2);
  background-color: transparent;
  display: flex;
  align-items: center;
  transition: 0.3s ease;

  svg {
    height: 16px;
    width: 16px;
  }

  &:hover {
    background-color: var(--Secondary-secondary_2);
    color: var(--white);
  }

  @media (max-width: 767px) {
    font-size: 14px;
    padding: 12px 18px;
  }

  @media (max-width: 575px) {
    padding: 6px 13px;
    gap: 4px;
    font-size: 12px;
  }
}

.addlly-secondary2 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  padding: 15px 30px;
  border-radius: 50px;
  gap: 8px;
  border: 1px solid var(--Secondary-secondary_2);
  color: var(--white);
  background: var(--Secondary-secondary_2);
  display: inline-block;
  align-items: center;

  @media (max-width: 575px) {
    padding: 6px 13px;
    gap: 4px;
    font-size: 12px;
  }
}

.btn {
  &:active {
    border-color: transparent !important;
  }
}

.light-purpal-btn {
  background: var(--Primary-main_shade_light_1);
  border: 1px solid var(--Primary-main_shade_light_1);
  color: var(--white);
  border-radius: 50px;
  padding: 12px 24px;
  display: flex;
  align-items: center;

  @media (max-width: 575px) {
    padding: 6px 13px;
  }

  svg {
    height: 18px;
    width: 18px;
  }

  span {
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;

    @media (max-width: 575px) {
      font-size: 12px;
    }
  }
}

.content-view-btn {
  background: var(--Primary-main_shade_light_1);
  border: 1px solid var(--Primary-main_shade_light_1);
  border-radius: 50px;
  color: var(--white);
  padding: 4px 17px;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 3;

  @media (max-width: 575px) {
    padding: 3px 13px;
    top: 8px;
    left: 12px;
    font-size: 12px;
  }

  span {
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;

    @media (max-width: 575px) {
      font-size: 10px;
    }
  }
}

.images-view-btn {
  background: var(--Primary-main_shade_light_1);
  border: 1px solid var(--Primary-main_shade_light_1);
  border-radius: 50px;
  color: var(--white);
  padding: 4px 17px;
  position: absolute;
  top: 20px;
  left: 180px;
  z-index: 3;

  @media (max-width: 575px) {
    padding: 3px 13px;
    top: 45px;
    left: 12px;
    font-size: 12px;
  }

  span {
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;

    @media (max-width: 575px) {
      font-size: 12px;
    }
  }
}

button:disabled {
  cursor: not-allowed;
}

/* ====================================== */
/* addlly Form */
/* ====================================== */
.addllyFormWrap {
  .form-group {
    label {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: var(--Dark-dark_2);
      margin-bottom: 10px;

      @media (max-width: 575px) {
        font-size: 12px;
        margin-bottom: 6px;
      }

      span {
        color: var(--Dark-dark_3);
      }
    }

    .addllyForm-control {
      width: 100%;
      outline: none;
      color: var(--Dark-dark_2);
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      background: var(--Dark-dark_7);
      border: 1px solid var(--Dark-dark_5);
      border-radius: 50px;
      padding: 15px 60px 15px 24px;

      @media (max-width: 575px) {
        padding: 10px;
        font-size: 12px;
      }

      &::placeholder {
        color: var(--Dark-dark_4);
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;

        @media (max-width: 575px) {
          font-size: 12px;
        }
      }

      &.is-invalid {
        border: 1px solid #dc3545;
      }

      &.listInput {
        color: var(--Dark-dark_3);
      }

      &.activeList {
        border: 1px solid var(--Primary-main_shade_light_1);
        background: var(--Primary-main_shade_light_2);
        color: var(--Dark-dark_2);
      }

      &.addllyForm-control-outline {
        background-color: transparent;
        border: 1px solid var(--Dark-dark_5);
      }

      &:disabled {
        border: 1px solid var(--Dark-dark_5);
        cursor: not-allowed;
      }
    }

    .addllyForm-control::-webkit-outer-spin-button,
    .addllyForm-control::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .passwordIcon {
      position: absolute;
      top: 44px;
      right: 20px;
      padding: 0;
      background-color: transparent;
      border: 0;
    }

    .listCheck {
      position: absolute;
      top: 13px;
      right: 20px;
      z-index: 9;
      cursor: pointer;
    }

    .useCheckBtn {
      position: absolute;
      right: 8px;
      top: 7px;
      font-size: 14px;
      font-weight: 500;
    }

    .addllyForm-select {
      .addlly-react-select__control {
        background: var(--Dark-dark_7);
        border: 1px solid var(--Dark-dark_6);
        border-radius: 50px;
        box-shadow: none;

        .addlly-react-select__value-container {
          padding: 9px 24px;

          .addlly-react-select__multi-value {
            background-color: #fff;
            border-radius: 20px;

            .addlly-react-select__multi-value__label {
              padding: 5px 8px;
              color: var(--Dark-dark_2);
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
            }

            .addlly-react-select__multi-value__remove {
              padding: 5px 8px;
            }
          }
        }
      }
    }

    .selectLang {
      padding: 0;
      margin: 0;
      display: flex;
      gap: 20px;

      li {
        list-style: none;
        display: flex;
        gap: 8px;
        cursor: pointer;
        background: var(--Dark-dark_7);
        border: 1px solid var(--Dark-dark_6);
        padding: 14px 24px;
        border-radius: 50px;

        &.active {
          border: 1px solid var(--primary-main);

          p {
            color: var(--primary-main);
          }
        }

        img {
          max-width: 28px;
        }
      }
    }
  }
}

/* ====================================== */
/* Article History */
/* ====================================== */
.text-width {
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dashboard-history {
  .res-table {
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
  }
}

.contentWrapper {
  overflow-x: hidden;
}

/* ====================================== */
/* Toaster Controls */
/* ====================================== */
.alert {
  &.error {
    position: relative;
    background: #ffeeee;
    border: 0;
    border-bottom: 2px solid #ff5050;
    color: #000;
    padding: 12px 8px;
    min-width: 20%;
    width: 100%;
  }

  &.success {
    position: relative;
    background: #eef8f3;
    border: 2px solid #4fb980;
    padding: 12px 8px;
    min-width: 20%;
    color: #000;
    width: 100%;
  }

  .close-error {
    position: absolute;
    right: 12px;
    top: 15px;
    cursor: pointer;
  }
}

.alert-warning,
.alert-success,
.alert-danger {
  border: 0;
  border-radius: 12px;
  padding-left: 48px;

  svg {
    position: absolute;
    top: 16px;
    left: 16px;
  }
}

.alert-warning {
  background-color: #fef8f0;
}

.alert-success {
  background-color: #eef8f3;
}

.alert-danger {
  background-color: #ffeeee;
}

.alert-link {
  color: #1646aa !important;
}

.errorField {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

/* Styles for the analytics table */
.analytics-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ccc;
}

.analytics-table th {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

.analytics-table tr {
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
}

.analytics-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

.dropdown-header {
  position: sticky;
  top: 0px;
  display: flex;
  justify-content: space-between;
  background: #f8f9fa;
  padding: 8px;
  font-weight: bold;
  border-bottom: 1px solid #dee2e6;
  z-index: 1;
}

.header-item {
  flex: 1;
  text-align: left;
}

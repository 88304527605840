.image-history-wrapper {
  .image-history-content {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 15px;
    width: 100%;
    max-height: 540px;
    overflow: auto;

    .image-content {
      display: flex;
      align-items: center;
      position: relative;
      justify-content: center;
      border-radius: 6px;
      overflow: hidden;
      width: 216px;
      height: 216px;
      background: linear-gradient(180deg, #ffffff 0%, #f3f4f7 100%);
      transition: background-color 0.4s ease-in-out;

      &:hover {
        cursor: zoom-in;
        background: none;
        background-color: rgba(0, 0, 0, 0.2);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .download-btn {
        position: absolute;
        top: 10px;
        right: 10px;

        .btn {
          background: #fff;
          border-color: #fff;
          font-size: 24px;
          padding: 2px 10px;
        }
      }
    }
  }
}

.image-view-modal {
  .modal-content {
    .modal-body {
      padding: 0;
      position: relative;

      .image-view-wrapper {
        text-align: center;
      }

      .image-view-wrapper img {
        border-radius: var(--bs-modal-border-radius);
        width: 100%;
        height: auto;
        max-height: 86vh;
        object-fit: contain;
      }

      .image-arrow-btn {
        .left-btn,
        .right-btn {
          border-radius: 50px;
          padding: 10px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }

        .left-btn {
          left: -65px;
        }

        .right-btn {
          right: -65px;
        }
      }

      .close-btn {
        svg path {
          fill: #fff;
          stroke: #fff;
        }
      }
    }
  }
}

.modal-image-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 16px;

  .image-content {
    border-radius: 10px;
    cursor: pointer;
    border: 2px solid transparent;
    overflow: hidden;
    padding: 2px;
    width: 220px;
    height: 220px;

    &:hover,
    &.active {
      border: 2px solid var(--primary-main);

      .image-download-btn,
      .image-view-btn {
        display: block;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }

    .image-download-btn {
      display: none;
      position: absolute;
      padding: 10px;
      right: 8px;
      top: 8px;
      white-space: nowrap;

      svg {
        font-size: 20px;
      }
    }

    .image-view-btn {
      display: none;
      position: absolute;
      padding: 10px;
      right: 58px;
      top: 8px;
      white-space: nowrap;

      svg {
        font-size: 20px;
      }
    }
  }
}

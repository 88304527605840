.content-view-btn.addlly-primary,
.images-view-btn.addlly-primary {
  background: var(--primary-main);
  border: 1px solid var(--primary-main);
}

.review-editor-content {
  .tox {
    .tox-sidebar-wrap {
      height: 68px !important;
      min-height: 68px !important;
    }

    .tox-edit-area__iframe {
      padding-bottom: 0px !important;
    }

    .tox-editor-header {
      .tox-toolbar-overlord {
        padding-left: 350px !important;
      }
    }
  }
}

.newsletter-editor {
  .tox {
    .tox-sidebar-wrap {
      height: 68px !important;
      min-height: 68px !important;
    }

    .tox-edit-area__iframe {
      padding-bottom: 0px !important;
    }

    .tox-editor-header {
      .tox-toolbar-overlord {
        padding-left: 180px !important;
      }
    }
  }

  .newsletter-html-wrapper,
  .pressRelease-html-wrapper {
    display: block;
    border: 2px solid var(--Dark-dark_5);
    border-top-width: 4px;
    border-bottom-width: 4px;
    border-radius: 8px;
    padding: 12px 16px;
    width: 100%;
  }

  .pressRelease-html-wrapper {
    height: 70vh;
    overflow: auto;
    white-space: pre-wrap;
  }
}

.difference-editor-content {
  .tox {
    .tox-sidebar-wrap {
      height: 100% !important;
      min-height: 100% !important;

      .tox-edit-area__iframe {
        padding: 0 !important;
      }
    }

    .tox-editor-header {
      display: none;
    }
  }
}

.review-article-wrapper {
  .sidebar-comment-wrapper {
    max-height: calc(75vh - 54px);
    overflow: auto;

    .comment-form-wrapper {
      background: #fff;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3), 0 4px 8px 3px rgba(0, 0, 0, 0.15);
    }

    .comment-form-history {
      background: #edf2fa;
      box-shadow: none;
      cursor: pointer;
      transition: transform 0.5s ease-in-out;

      &:hover {
        background: #e7edf8;
        box-shadow: none;

        .resolve-btn,
        .option-btn {
          display: block !important;
        }
      }

      &.active-comment {
        background: #fff;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3), 0 4px 8px 3px rgba(0, 0, 0, 0.15);
        cursor: auto;
        transform: translate(-16px, 0px);

        .resolve-btn,
        .option-btn {
          display: block !important;
        }
      }

      .comment-content {
        .comment-div {
          font-size: 18px;
          line-height: 26px;
          margin-bottom: 0;
          cursor: pointer;
          padding: 3px 21px 3px 5px;
          word-wrap: break-word;
          white-space: pre-wrap;

          &:hover {
            background: rgba(68, 71, 10, 0.078);
            border-radius: 8px;
            outline: none;
          }
        }

        .messages {
          flex-grow: 1;
          overflow-y: auto;

          .message {
            background-color: #7777ff;
            color: #fff;
            border-radius: 16px;
            border-bottom-right-radius: 0px;
            margin-left: auto;
            padding: 8px 12px;
            margin-bottom: 10px;
            max-width: 70%;

            pre {
              font-size: 16px;
              line-height: 26px;
              margin-bottom: 0;
              word-wrap: break-word;
              white-space: pre-wrap;
            }

            p {
              color: #edf2fa;
              margin-top: 6px;
              text-align: end;
            }
          }

          .user {
            border-radius: 16px;
            border-bottom-left-radius: 0px;
            margin-left: 0;
          }

          .resolve,
          .reopen {
            background-color: #dddde9;
            border-radius: 10px;
            color: #000;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 20px auto;
            padding: 6px 8px;

            pre {
              font-size: 14px;
            }

            p {
              color: #000;
              font-size: 12px;
              margin: 0;
            }
          }
        }
      }
    }

    .comment-form-wrapper,
    .comment-form-history {
      border-radius: 12px;
      border: none;
      border-color: transparent;
      padding: 12px 16px;

      .user-info {
        .userIcon {
          max-width: 42px;
          min-height: 42px;

          @media (max-width: 767px) {
            max-width: 26px;
            min-height: 26px;
          }
        }

        .avatar {
          background-color: var(--primary-main);
          height: 42px;
          width: 42px;
          border-radius: 50px;
          text-align: center;
          line-height: 42px;
          font-size: 20px;
          border: 2px solid var(--white);
          font-weight: 500;
        }

        .resolve-btn,
        .option-btn {
          border-radius: 50px;
          padding: 6px 8px;
          background-color: transparent;
          border: none;
          display: none;

          &:hover {
            background-color: var(--bs-btn-hover-bg);
          }
        }

        .option-dropdown-wrapper {
          padding: 0;
          background: transparent;
          border: 0;
          display: flex;
          align-items: center;

          &:after {
            content: none;
          }
        }

        p {
          &:first-child {
            color: var(--Dark-dark_1);
            font-weight: 700;
          }
        }
      }

      .user-info {
        p {
          &:nth-child(2) {
            font-size: 13px;
          }
        }
      }
    }
  }

  .sidebar-version-wrapper {
    max-height: calc(75vh - 54px);
    overflow: auto;

    .version-content {
      cursor: pointer;
      position: relative;

      &:hover {
        background: #e7edf8;
      }

      &.active {
        background: #e7edf8;
        box-shadow: none;
        color: var(--primary-main);

        .restore-btn {
          display: block;
        }
      }

      .restore-btn {
        border-radius: 50px;
        padding: 6px 8px;
        background-color: transparent;
        border: none;
        display: none;
        position: absolute;
        top: 6px;
        right: 6px;

        &:hover {
          background-color: var(--bs-btn-hover-bg);
        }
      }
    }
  }
}

.loginWrap {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .authenticateBox {
    padding: 36px 30px 24px 30px;
    background-color: var(--white);
    border: 1px solid var(--Dark-dark_6);
    border-radius: 16px;
    width: 100%;
    max-width: 500px;
    .boxTitle {
      color: var(--Dark-dark_1);
      text-align: center;
      font-weight: 600;
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 30px;
    }
    .iconFormGrp {
      margin-bottom: 20px;
      position: relative;
      .inputIcon {
        position: absolute;
        top: 14px;
        left: 20px;
      }
      input {
        border: 1px solid var(--Dark-dark_6);
        border-radius: 50px;
        background: var(--Dark-dark_7);
        width: 100%;
        outline: none;
        padding: 16px 52px;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: var(--Dark-dark_3);
      }
      .invalid-feedback {
        display: none;
        width: 100%;
        margin-top: 0.25rem;
        font-size: 0.875em;
        color: #dc3545;
      }
      .is-invalid {
        & ~ .invalid-feedback {
          display: block;
        }
      }
      .passwordIcon {
        position: absolute;
        top: 14px;
        right: 20px;
        padding: 0;
        background-color: transparent;
        border: 0;
      }
    }
    .submitBtn {
      button {
        width: 100%;
        border: 0px;
        font-size: 16px;
        color: var(--white);
        font-weight: 600;
        line-height: 20px;
        padding: 15px 24px;
        border-radius: 50px;
        background: var(--Dark-dark_1);
      }
    }
  }
}

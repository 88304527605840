.preview-container {
  border: 1px solid #ececf4;
  border-radius: 8px;
  padding: 16px;
  height: 100%;

  .desc-content {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: calc(100vw - 428px);
  }
}

.post-preview-container {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 6px;
  border-radius: 10px;
  max-width: 350px;
  margin: 0 auto;
  overflow: hidden;

  .preview-header {
    border-bottom: 1px solid #ececf4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;

    .profile-pic {
      width: 32px;
      height: 32px;
      min-width: 32px;
      background: #0000ff;
      border-radius: 50%;
    }

    .search-box {
      position: relative;
      margin: 0 16px;
      width: 100%;

      input {
        border-radius: 4px;
        padding: 10px 16px 10px 36px;
      }

      .search-icon {
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        font-size: 20px;
      }
    }

    .message-icon {
      font-size: 22px;
      min-width: 22px;
    }

    .fb-logo {
      img {
        width: 128px;
      }
    }

    .ig-logo {
      img {
        width: 100px;
      }
    }

    .fb-header-icon {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #e2e2e2;
      border-radius: 50px;

      svg {
        font-size: 22px;
      }
    }

    .ig-header-icon {
      font-size: 22px;
    }

    .tw-header-icon {
      font-size: 24px;
    }
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .preview-body::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .preview-body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .preview-body {
    .post-content {
      height: 498px;
      overflow: auto;

      .post-header {
        background: #f8f9fa;
        padding: 12px 16px;

        .user-info {
          display: flex;
          align-items: center;

          .user-img {
            width: 50px;
            height: 50px;
            min-width: 50px;
            background: #0000ff;
          }

          .fb-user-img {
            width: 36px;
            height: 36px;
            min-width: 36px;
            background: #0000ff;
            border-radius: 50px;
          }

          .user-desc {
            width: 100%;
            margin: 0 16px;

            p {
              line-height: 1;
            }
          }

          .option-icon {
            color: #808080;
            font-size: 28px;
            min-width: 32px;
          }
        }

        .post-desc {
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          margin: 16px 0 0;
          position: relative;

          p {
            min-height: 14px;
          }

          pre {
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            overflow: hidden;
            white-space: pre-wrap;
            margin-bottom: 1.5rem;
          }
        }
      }

      .post-img {
        background: #e2e2e2;

        .img-wrapper {
          width: 100%;
          height: auto;
          background: rgba(0, 0, 255, 0.5);
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .img-desc {
          padding: 6px 20px;
        }
      }

      .ig-post-img {
        .img-wrapper {
          width: 100%;
          height: auto;
          background: rgba(0, 0, 255, 0.5);
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .post-content-wrapper {
        background: #f8f9fa;
        padding: 0 16px 8px 68px;

        .tw-post-desc {
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          position: relative;

          p {
            min-height: 14px;
          }

          pre {
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            overflow: hidden;
            white-space: pre-wrap;
            margin-bottom: 1.5rem;
          }
        }

        .img-wrapper {
          width: 100%;
          height: auto;
          background: rgba(0, 0, 255, 0.5);
          border-radius: 16px;
          overflow: hidden;
          margin-top: 12px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .post-footer {
          display: flex;
          align-items: center;
          padding: 0 8px;
          line-height: 1;
          font-size: 12px;

          svg {
            font-size: 18px;
            margin-right: 8px;
          }
        }
      }

      .post-footer {
        padding: 0 16px;
        background: #f8f9fa;

        .comment-desc {
          display: flex;
          align-items: center;
          padding: 12px 0;
          border-bottom: 1px solid #ececf4;

          .dot {
            display: block;
            width: 12px;
            height: 12px;
            border-radius: 50px;
            margin-right: 6px;
          }

          .comment-label {
            font-size: 10px;
          }
        }

        .dot.dot-1 {
          background: #0dcaf0;
        }
        .dot.dot-2 {
          background: #e6b5ff;
        }
        .dot.dot-3 {
          background: #ffba00;
        }

        .li-comment-desc,
        .fb-comment-desc {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 12px 0;
          border-bottom: 1px solid #ececf4;

          .dot {
            display: block;
            width: 20px;
            height: 20px;
            border-radius: 50px;
            margin-left: -6px;
          }
        }

        .post-actions {
          padding: 6px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;

          div {
            text-align: center;

            .action-icon {
              font-size: 20px;
            }
          }
        }
      }

      .ig-post-content {
        background: #f8f9fa;
        padding: 12px 16px;

        .post-icon {
          width: 20px;
          height: 20px;
        }

        .post-desc {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          position: relative;

          p {
            min-height: 14px;
          }

          pre {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            white-space: pre-wrap;
            margin-bottom: 1.5rem;
          }
        }
      }
    }
  }

  .more-btn {
    cursor: pointer;
    background: #f8f9fa;
    display: flex;
    align-items: center;
    color: var(--primary-main);
    font-size: 14px;
    position: absolute;
    bottom: 0;
    right: 0px;
    padding: 0 8px;

    svg {
      font-size: 16px;
      margin-right: 4px;
    }
  }

  .preview-footer {
    border-top: 1px solid #ececf4;
    padding: 16px 28px 8px;

    .footer-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .action-icon {
        min-width: 28px;
        width: 28px;
        color: #495057;
      }
    }

    .home-button {
      width: 68px;
      height: 4px;
      background: #000;
      border-radius: 50px;
      margin: 12px auto 0;
    }
  }

  .post-select-checkbox {
    .form-check {
      display: flex;
      align-items: center;

      .form-check-input {
        box-shadow: none;
        outline: none;
        cursor: pointer;
        width: 24px;
        height: 24px;
        margin-top: 0;
      }

      .form-check-label {
        cursor: pointer;
      }
    }
  }

  .carousel-wrapper {
    &:hover {
      .arrow-button {
        display: block;
      }
    }

    .slides {
      display: flex;
      overflow-x: hidden;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;

      & > div {
        scroll-snap-align: start;
        flex-shrink: 0;
        transform-origin: center center;
        transition: transform 0.5s;
        position: relative;
        width: 100%;
        height: 100%;
      }
    }

    .arrow-button {
      display: none;
    }

    .left-arrow-button,
    .right-arrow-button {
      background-color: rgba(17, 23, 29, 0.6);
      border-radius: 50%;
      color: #fff;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      height: 32px;
      width: 32px;
      top: 50%;
      transform: translateY(-50%);
    }

    .left-arrow-button {
      left: 5px;
    }

    .right-arrow-button {
      right: 5px;
    }
  }
}

.custom-post-loader {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;

  img {
    width: 100px;
    height: 100px;
  }
}

.image-upload-content {
  .img-wrapper {
    height: auto !important;
    min-height: 150px;

    &:hover {
      .image-upload-btn {
        display: block;
      }
    }

    .image-upload-btn-div {
      background: #ffffff66;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      svg {
        font-size: 20px;
      }
    }

    .image-upload-btn {
      display: none;
      position: absolute;
      padding: 14px;
      right: 8px;
      top: 8px;
      white-space: nowrap;

      svg {
        font-size: 20px;
      }
    }
  }

  .img-wrapper.bg-dark {
    .image-upload-btn {
      display: block;
    }
  }
}

.fact-checker-preview {
  .url-wrapper {
    // text-primary cursor-pointer text-decoration-none mb-2
    .link-content {
      color: #4d5156;
      cursor: default;
      display: block;
      text-decoration: none;
      width: fit-content;

      .link-image {
        align-items: center;
        background: var(--Dark-dark_5);
        border-radius: 50px;
        display: flex;
        height: 32px;
        justify-content: center;
        margin-right: 12px;
        width: 32px;

        svg {
          font-size: 20px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 50px;
          overflow: hidden;
        }
      }

      > div {
        cursor: pointer;
        width: fit-content;

        &:hover {
          ~ h3 {
            text-decoration: underline;
          }
        }
      }

      h3 {
        color: rgba(var(--bs-primary-rgb), 1);
        cursor: pointer;
        display: -webkit-box;
        font-size: 18px;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.empty-place {
  height: 16px;
  border-bottom: 16px solid #f1f1f6;
  margin-top: 10px;
}

.selectItem {
  padding: 0;
  margin: 0;
  display: flex;
  gap: 20px;

  li {
    list-style: none;
    display: flex;
    gap: 8px;
    cursor: pointer;
    background: var(--Dark-dark_7);
    border: 1px solid var(--Dark-dark_6);
    padding: 14px 24px;
    border-radius: 50px;

    &.active {
      border: 1px solid var(--primary-main);

      p {
        color: var(--primary-main);
      }
    }

    img {
      max-width: 28px;
    }
  }
}

.seoOptBlogTopic.google-web-wrapper {
  overflow: auto;
  padding-left: 0;
}

.seoOptBlogTopic.list-item-table {
  .listGroup {
    border: 0;
    border-radius: 0 !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    width: 30%;

    @media only screen and (max-width: 610px) {
      width: 100%;
    }

    img {
      width: 32px;
    }
  }

  .listGroupAction {
    border: 0;
    border-radius: 0 !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    width: 10%;

    @media only screen and (max-width: 610px) {
      width: 100%;
    }
  }

  .list-group-item {
    display: flex;
    padding: 0.5rem;

    .useCheckBtn {
      color: var(--primary-main);
      display: block;
    }

    .list-item-textarea {
      color: var(--Dark-dark_2);
      background: transparent;
      border: 1px solid var(--Dark-dark_5);
      border-radius: 0.375rem;
      font-size: 14px;
      font-weight: 400;
      min-height: 75px;
      padding: 0.5rem;
      overflow: hidden;

      &:focus {
        outline: none;
      }
    }

    .form-select {
      border: 0;
      height: 100%;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .list-group-item.list-group-header {
    font-weight: bold;
    justify-content: center;
    padding: 0.5rem;
    border: 0;
    background-color: var(--Primary-main_shade_light_2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0;
  }
}

.setOfOutline {
  margin: 0;

  li {
    border-bottom: 1px solid var(--Dark-dark_5);
    padding: 20px 0;

    .checkListItem {
      display: flex;
      padding: 16px;
      background: var(--Dark-dark_7);
      border-radius: 8px;
      margin-bottom: 12px;
      justify-content: space-between;

      .addedOutline {
        background: transparent;
        outline: none;
        border: 0;
        color: var(--Dark-dark_3);
        font-size: 14px;
        margin-bottom: 0;
        font-weight: 400;
      }
    }

    .AddOutline {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: var(--Secondary-secondary_2);
      padding: 0;
      border: 0;
      background: transparent;
    }
  }
}

.seoOptBlogTopic {
  .checkListItem {
    display: flex;
    padding: 16px;
    background: var(--Dark-dark_7);
    border-radius: 8px;
    margin-bottom: 12px;
    justify-content: space-between;

    .google-content {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .keyword-input {
    padding-right: 350px !important;
  }

  .keyword-volume {
    position: absolute;
    right: 60px;
    top: 15px;
  }
}

.custom-checkbox .form-check-input {
  box-shadow: none;
  cursor: pointer;
  height: 22px;
  margin-top: 0;
  outline: none;
  width: 22px;
}

.checkmarkContainer {
  display: block;
  position: relative;
  padding-left: 24px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.useCheckBtn {
    right: 20px !important;
    top: 50% !important;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~ .checkmark {
        &:after {
          display: block;
        }
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    border: 1px solid var(--primary-main);
    border-radius: 50%;

    &::after {
      content: "";
      position: absolute;
      display: none;
      left: 50%;
      top: 50%;
      width: 12px;
      height: 12px;
      background: var(--primary-main);
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.table-post-content {
  display: flex;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }

  div {
    margin-left: 12px;
  }
}

.media-post-content {
  border: 2px solid var(--Dark-dark_5);
  border-radius: 8px;
  padding: 12px;
  height: 500px;
  overflow: auto;

  &.active {
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0px 0px 0px 1px rgba(17, 24, 39, 0.05),
      0px 1px 4px rgba(17, 24, 39, 0.1), 0px 8px 16px rgba(17, 24, 39, 0.1);
    border: 2px solid var(--primary-main);
    border-top-width: 4px;
    border-bottom-width: 4px;
  }

  pre {
    font-family: "Jost", sans-serif;
    white-space: pre-wrap;
  }
}

.hashtag-content {
  box-shadow: 0 0 #0000, 0 0 #0000, 0px 0px 0px 1px rgba(17, 24, 39, 0.05), 0px 1px 4px rgba(17, 24, 39, 0.1),
    0px 0px 8px rgba(17, 24, 39, 0.1);
  border-radius: 6px;
  padding: 8px;

  label {
    cursor: pointer;
  }

  .form-check-input {
    box-shadow: none;
    outline: none;
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-left: -1em;
    margin-top: 12px;
  }

  .custom-input {
    .form-check-input {
      margin-left: 8px;
    }
  }

  .hashtag-input {
    input {
      background: transparent;
      border: 0;
      outline: 0;
      width: 100%;
    }
  }
}

.custom-slider {
  .slick-slider {
    padding: 0 26px;
  }

  .slick-prev {
    left: -6px;
  }
  .slick-next {
    right: -6px;
  }

  .slick-prev,
  .slick-next {
    color: #000;
    width: 26px;
    height: 26px;
  }

  .slick-prev:before,
  .slick-next:before {
    color: #000;
    font-size: 26px;
  }
}

.custom-textarea-editor {
  .textarea-header {
    background-color: var(--Dark-dark_7);
    border: 1px solid var(--Dark-dark_5);
    border-bottom: 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-size: 18px;
    padding: 16px 24px;
  }

  .textarea-content {
    border: 1px solid var(--Dark-dark_5);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.image-wrapper {
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0px 0px 0px 1px rgba(17, 24, 39, 0.05),
        0px 1px 4px rgba(17, 24, 39, 0.1), 0px 8px 16px rgba(17, 24, 39, 0.1);
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid #f4f4f4;
    img {
        width: 100%;
        height: 320px;
        object-fit: cover;
    }

    @media (max-width: 1440px) {
        img {
            height: 260px;
        }
    }

    @media (max-width: 1199px) {
        img {
            height: 220px;
        }
    }

    .card-product-info {
        width: 100%;
        height: 100%;
        padding: 12px 16px;
        background: var(--Dark-dark_7);
        p {
            font-size: 16px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-weight: 500;
            color: black;
        }
    }
}
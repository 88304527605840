.main-smart-ai-writer {
  .smart-ai-writer {
    button {
      padding: 48px 30px 48px 30px;

      @media (max-width: 767px) {
        padding: 18px 15px 24px 15px;
      }

      img {
        margin-bottom: 34px;

        @media (max-width: 767px) {
          margin-bottom: 17px;
        }

        @media (max-width: 575px) {
          margin-bottom: 14px;
        }
      }
    }
  }

  @media (max-width: 575px) {
    .recent-trends {
      table {
        td {
          &:first-child {
            div {
              min-width: 200px;

              img {
                margin-bottom: 10px;
              }
            }
          }
        }

        td {
          &:nth-child(2) {
            text-align: center;

            h3 {
              min-width: 100px;
            }
          }
        }

        td {
          &:nth-child(3) {
            h6 {
              min-width: 150px;
            }
          }
        }
      }
    }
  }
}

// *****************START Instant AI Writer First Step*****************
.AIWriterBlog {
  padding: 30px 24px 24px;

  @media (max-width: 575px) {
    padding: 30px 16px 24px;
  }

  .content-score {
    .heading-summary {
      display: flex;
      gap: 16px;
      justify-content: space-evenly;
    }

    .tags {
      max-height: 400px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .progress-bars {
      .progress-bar {
        background-color: var(--primary-main);
      }
    }

    .score-details {
      margin-top: 10px;
      margin-bottom: 10px;

      .col-xl-6 {
        margin-top: 6px;
        margin-bottom: 6px;
      }
    }

    .nav-tabs {
      .nav-link {
        color: var(--Dark-dark_4);
        text-transform: uppercase;

        &:hover {
          border-color: transparent;
        }

        &.active {
          border-color: transparent;
          border-bottom: 3px solid var(--Secondary-secondary_2);
          color: var(--Secondary-secondary_2);
        }
      }
    }
  }

  .AIWriterBlogHead {
    padding-bottom: 20px;
    margin-bottom: 24px;
    border-bottom: 1px solid var(--Dark-dark_6);

    @media (max-width: 575px) {
      padding-bottom: 12px;
      margin-bottom: 12px;
    }

    .AIWriterBlogHeadTop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;

      @media (max-width: 575px) {
        flex-flow: column;
        align-items: flex-start;
        gap: 8px;
      }

      h3 {
        margin-bottom: 0;
        line-height: 24px;
      }
    }

    .question-mark {
      padding: 10px;
      height: 25px;
      width: 25px;
      justify-content: center;
      display: flex;
    }

    .AIWriterStepper {
      display: flex;
      align-items: center;
      gap: 40px;

      @media (max-width: 575px) {
        width: 100%;
      }

      ul {
        display: flex;
        gap: 16px;
        margin: 0;
        padding: 0;

        @media (max-width: 575px) {
          justify-content: flex-end;
          gap: 10px;
        }

        li {
          list-style: none;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          display: flex;
          gap: 8px;

          @media (max-width: 575px) {
            line-height: 18px;
            gap: 5px;
          }

          &.active {
            span {
              color: #fff;
              background: var(--Secondary-secondary_2);
              border: 1px solid var(--Secondary-secondary_2);
            }

            p {
              color: var(--Dark-dark_1);
            }
          }

          &.complete {
            svg {
              color: #fff;
              background: var(--Status-success);
              border: 1px solid var(--Status-success);
              border-radius: 50%;
              height: 24px;
              width: 24px;

              @media (max-width: 575px) {
                width: 20px;
                height: 20px;
              }
            }

            p {
              color: var(--Dark-dark_3);
            }
          }

          span {
            width: 24px;
            height: 24px;
            color: var(--Dark-dark_3);
            border-radius: 50%;
            text-align: center;
            background: var(--Dark-dark_7);
            border: 1px solid var(--Dark-dark_4);

            @media (max-width: 575px) {
              width: 20px;
              height: 20px;
              font-size: 12px;
            }
          }

          p {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}

.first-step-detail {
  .mobile-row {
    margin-top: -12px;
    margin-bottom: -12px;

    .mobile-col {
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }

  .google-trends {
    .progress-bar {
      background-color: var(--primary-main);
    }

    .custom-particular-box {
      &::after {
        top: -105px;
      }
    }

    .google-trends-loader {
      height: 115px;
      width: 115px;
    }
  }

  .particular-box {
    position: relative;
    overflow: hidden;

    span {
      font-size: 48px;
      line-height: 58px;
      color: var(--Dark-dark_5);
      z-index: 1;

      @media (max-width: 767px) {
        font-size: 38px;
      }
    }
  }

  .particular-box {
    &.custom-particular-box {
      border: 1px solid var(--Dark-dark_5);

      &::after {
        top: -125px;
        right: -110px;
        bottom: auto;
        left: auto;
      }
    }

    &::after {
      content: "";
      width: 240px;
      height: 240px;
      background-color: var(--Dark-dark_1);
      opacity: 0.03;
      position: absolute;
      bottom: 60%;
      left: 60%;
      border-radius: 50%;

      @media (max-width: 1440px) {
        bottom: 50%;
        left: 65%;
      }

      @media (max-width: 1199px) {
        bottom: 56%;
        left: 60%;
      }

      @media (max-width: 991px) {
        bottom: 50%;
        left: 62%;
      }

      @media (max-width: 767px) {
        bottom: 55%;
        left: 58%;
      }

      @media (max-width: 575px) {
        bottom: 40%;
        left: 78%;
      }

      @media (max-width: 420px) {
        bottom: 40%;
        left: 70%;
      }
    }

    img {
      height: 60px;
      width: 60px;
    }
  }
}

// *****************END Instant AI Writer First Step*****************
// ***************** Realtime SEO Advanced Writer *****************
.headline-step {
  .addllyFormWrap {
    .form-group {
      .addllyForm-control {
        border-radius: 8px;
        background-color: transparent;
        padding: 15px;
        border: none;
      }
    }
  }

  .headline {
    .activeList {
      .refresh-btn {
        display: none;
      }
    }

    li {
      border: 1px solid var(--Dark-dark_6);

      &:hover {
        border: 1px solid var(--Primary-main_shade_light_1);
        background-color: var(--Primary-main_shade_light_2);
      }

      .useCheckBtn {
        display: block;
      }
    }

    li {
      &.activeList {
        border: 1px solid var(--Primary-main_shade_light_1);
        background: var(--Primary-main_shade_light_2);
        color: var(--Dark-dark_2);
      }
    }

    .refresh-btn {
      background-color: transparent;
      border: none;
      margin-right: 15px;
    }

    .addllyFormWrap {
      .form-group {
        .useCheckBtn {
          right: 38px;
        }
      }
    }
  }
}

// ***************** end Realtime SEO Advanced Writer *****************
#tooltip-top {
  & > .tooltip-inner {
    background-color: #fff;
    color: #000;
    border: 1px solid #fff;
  }

  & > .tooltip-arrow {
    &::before {
      border-color: #fff;
    }
  }
}

// *****************END Instant AI Writer Seven Step*****************
.semantic-multi-select {
  &.addllyFormWrap {
    .form-group {
      .addllyForm-control {
        &.activeList {
          cursor: pointer;
        }
      }
    }
  }
}

// **

// ***************** edit content *****************
.faq-schema {
  .tox {
    .tox-editor-header {
      .tox-toolbar-overlord {
        padding-left: 0 !important;
      }
    }
  }
}

.tox {
  .tox-edit-area__iframe {
    padding-bottom: 30px !important;
  }

  &:not(.tox-tinymce-inline) {
    .tox-editor-header {
      padding: 16px 0 !important;

      @media (max-width: 575px) {
        padding: 20px 0 !important;
      }
    }
  }

  .tox-sidebar-wrap {
    min-height: 100vh !important;
  }

  &.tox-tinymce {
    border: 1px solid var(--Dark-dark_5) !important;
    border-radius: 8px !important;
  }

  .tox-statusbar {
    border-top: none;
    display: none !important;
  }

  .tox-editor-header {
    background-color: var(--Dark-dark_7) !important;
    border-bottom: 1px solid var(--Dark-dark_5) !important;

    .tox-toolbar {
      @media (max-width: 1440px) {
        padding-left: 160px !important;
      }
    }

    .tox-toolbar-overlord {
      background-color: var(--Dark-dark_7) !important;
      padding-left: 370px !important;

      @media (max-width: 480px) {
        padding-left: 250px !important;
      }

      @media (max-width: 350px) {
        padding-left: 185px !important;
      }

      .tox-toolbar__primary {
        background-color: var(--Dark-dark_7) !important;

        @media (max-width: 767px) {
          display: flex;
          justify-content: end;
        }
      }
    }
  }
}

.html-content {
  border-radius: 8px;
  overflow: hidden;

  &.faq-schema {
    .editorMinHeightHtml {
      padding-top: 0px;
    }
  }

  .editorMinHeight {
    min-height: 100vh;
  }

  .editorMinHeightHtml {
    padding-top: 74px;
    background: #1e1e1e;
  }
}

.que-modal {
  .modal-dialog {
    max-width: 850px;

    @media (max-width: 992px) {
      max-width: 850px;
    }

    @media (max-width: 767px) {
      max-width: 550px;
    }
  }

  .modal-content {
    max-width: 850px;

    .modal-body {
      width: 100%;

      .particular-box {
        border: 1px solid var(--Dark-dark_5);
      }
    }
  }
}

@media (max-width: 768px) {
  .modal-content {
    max-width: 100% !important;
  }
}

.note-modal {
  .modal-content {
    max-width: 620px;

    .modal-body {
      width: 100%;

      .modal-details {
        align-items: center;
        gap: 8px;
        margin-bottom: 24px;
        margin-top: 30px;

        .icon-box {
          height: 60px;
          width: 65px;
          border-radius: 50%;
          border: 5px solid var(--Dark-dark_5);
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--white);
        }

        .content {
          display: flex;
          width: 100%;

          h6 {
            padding: 0 10px;
            display: flex;
            align-items: center;
            color: var(--white);
            border-radius: 10px 0 0 10px;
          }

          .inner-content {
            background-color: var(--Dark-dark_6);
            border-radius: 0 10px 10px 0;
            width: 100%;
            padding: 10px;

            h5 {
              text-transform: uppercase;
              font-weight: 600;
              font-size: 14px;
              text-align: left;
            }

            p {
              text-align: left;
            }
          }
        }
      }
    }
  }
}

// -- before ticket
.smartWriterTab {
  .nav-tabs {
    .nav-item {
      .nav-link {
        color: var(--Dark-dark_3);

        &.active {
          color: var(--primary-main);
        }
      }
    }
  }
}

.pie-chart {
  position: relative;
  text-align: center;

  p {
    text-align: center;
    position: absolute;
    left: -67px;
    right: 0;
    bottom: -3px;
    font-size: 20px;
  }
}

.outlineTextarea {
  border: 1px solid var(--Dark-dark_6);
  border-radius: 8px;
  outline: none;
  padding: 16px 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--Dark-dark_2);
}

.preview-container {
  border: 1px solid #ececf4;
  border-radius: 8px;
  padding: 16px;
  height: 100%;

  .desc-content {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
